import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PropertiesComponent from "../components/PropertiesComponent";

const PropertiesPage = () => {
  return (
    <div className="flex flex-col min-h-screen animate-fade-in">
      <NavBar />
      <div className="mx-4 lg:mx-32 flex flex-col justify-center items-center mt-16 animate-slide-up">
        <h1 className="font-medium text-3xl lg:text-5xl font-serif text-center animate-fade-in">
          Properties for sale in your favorite area
        </h1>
        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>
          <h1 className="mt-6 w-11/12 lg:w-3/5 text-center text-lg lg:text-xl font-light animate-fade-in">
            Dream Home is a gated community with a great location. Located
            downtown, you’re within walking distance of Parks, and the best
            shopping, dining, and entertainment. Getting around is a breeze,
            with easy access to freeways, buses, and trolleys.
          </h1>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-20 justify-between w-full mb-14">
          <PropertiesComponent className="animate-zoom-in" />
          <PropertiesComponent className="animate-zoom-in" />
          <PropertiesComponent className="animate-zoom-in" />
          <PropertiesComponent className="animate-zoom-in" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PropertiesPage;
