import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const AboutPage = () => {
  return (
    <div>
      <NavBar />
      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Heading with Slide-up Animation */}
        <h1 className="font-medium text-3xl sm:text-4xl md:text-5xl font-serif text-center animate-slide-up">
          Why RAK Groups?
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          {/* Paragraph with Fade-in and Delay */}
          <h1 className="mt-6 w-11/12 sm:w-4/5 md:w-3/5 text-center text-base sm:text-lg md:text-xl font-light animate-fade-in delay-200">
            Dream Home is a gated community with a great location. Located
            downtown, you’re within walking distance of Parks, and the best
            shopping, dining, and entertainment. Getting around is a breeze,
            with easy access to freeways, buses, and trolleys. Laundry is
            available on the premises.
          </h1>
        </div>
      </div>

      {/* About Us Section */}
      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-12 animate-fade-in delay-800">
        <h1 className="font-medium text-3xl sm:text-4xl md:text-5xl font-serif text-center animate-slide-up">
          About Us
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          <h1 className="mt-6 w-11/12 sm:w-4/5 md:w-3/5 text-center text-base sm:text-lg md:text-xl font-light animate-fade-in delay-900">
            Dream Home is a gated community with a great location. Located
            downtown, you’re within walking distance of Parks, and the best
            shopping, dining, and entertainment. Getting around is a breeze,
            with easy access to freeways, buses, and trolleys. Laundry is
            available on the premises.
          </h1>
        </div>

        {/* Stats Section with Zoom-in Animation */}
        <div className="mt-12 flex flex-wrap justify-center gap-6 sm:gap-10 lg:gap-20 w-full mb-14 text-center">
          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1000">
            <h1 className="text-4xl sm:text-5xl font-bold text-yellow-500">
              500+
            </h1>
            <h1 className="text-xl sm:text-2xl text-black font-semibold">
              Projects
            </h1>
            <h1 className="text-sm sm:text-base md:text-xl text-black font-light">
              Over 500 luxury villas for a “Home Away From Home” experience
            </h1>
          </div>

          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1100">
            <h1 className="text-4xl sm:text-5xl font-bold text-yellow-500">
              40+
            </h1>
            <h1 className="text-xl sm:text-2xl text-black font-semibold">
              Locations
            </h1>
            <h1 className="text-sm sm:text-base md:text-xl text-black font-light">
              Luxury villas and private holiday homes from all across
            </h1>
          </div>

          <div className="flex flex-col space-y-2 justify-center items-center w-2/5 sm:w-1/4 animate-zoom-in delay-1200">
            <h1 className="text-4xl sm:text-5xl font-bold text-yellow-500">
              500+
            </h1>
            <h1 className="text-xl sm:text-2xl text-black font-semibold">
              Help
            </h1>
            <h1 className="text-sm sm:text-base md:text-xl text-black font-light">
              24/7 Help service for all customers to guide and support
            </h1>
          </div>
        </div>

        <div className="mt-12 flex flex-wrap justify-center items-center gap-6 sm:gap-10 lg:gap-20 w-full mb-14 text-left">
          <svg
            width="104"
            height="104"
            viewBox="0 0 68 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M42.7273 65.0861V47.6024C42.7273 46.8295 42.4208 46.0884 41.8752 45.5419C41.3297 44.9954 40.5897 44.6884 39.8182 44.6884H28.1818C27.4103 44.6884 26.6703 44.9954 26.1248 45.5419C25.5792 46.0884 25.2727 46.8295 25.2727 47.6024V65.0861C25.2727 65.8589 24.9662 66.6001 24.4207 67.1465C23.8751 67.693 23.1352 68 22.3636 68H4.90909C4.13755 68 3.39761 67.693 2.85205 67.1465C2.30649 66.6001 2 65.8589 2 65.0861V31.3935C2.00651 30.9903 2.09352 30.5924 2.25589 30.2233C2.41826 29.8542 2.65272 29.5214 2.94545 29.2445L32.0364 2.76399C32.5726 2.27255 33.2732 2 34 2C34.7268 2 35.4274 2.27255 35.9636 2.76399L65.0545 29.2445C65.3473 29.5214 65.5817 29.8542 65.7441 30.2233C65.9065 30.5924 65.9935 30.9903 66 31.3935V65.0861C66 65.8589 65.6935 66.6001 65.148 67.1465C64.6024 67.693 63.8624 68 63.0909 68H45.6364C44.8648 68 44.1249 67.693 43.5793 67.1465C43.0338 66.6001 42.7273 65.8589 42.7273 65.0861Z"
              stroke="url(#paint0_linear_588_4306)"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_588_4306"
                x1="34"
                y1="2"
                x2="34"
                y2="68"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.744" stop-color="#CCAC00" stop-opacity="0.8" />
                <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>

          <div className="flex flex-col space-y-2 justify-center w-2/5 sm:w-1/4 animate-zoom-in delay-1000">


            <h1 className="text-xl sm:text-2xl text-black font-semibold">
              Our Vision
            </h1>
            <h1 className="text-sm sm:text-base md:text-xl text-black font-light">
            Distinctively re-engineer revolutionary meta-services and premium  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
            </h1>
          </div>
          <svg
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 102.5V1.5H102.5V102.5H1.5Z"
                stroke="url(#paint0_linear_588_4309)"
                stroke-width="3"
              />
              <path
                d="M58.5 87.75V16.25C58.5 15.388 58.1576 14.5614 57.5481 13.9519C56.9386 13.3424 56.112 13 55.25 13H16.25C15.388 13 14.5614 13.3424 13.9519 13.9519C13.3424 14.5614 13 15.388 13 16.25V87.75"
                stroke="url(#paint1_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M91 87.75V42.25C91 41.388 90.6576 40.5614 90.0481 39.9519C89.4386 39.3424 88.612 39 87.75 39H58.5"
                stroke="url(#paint2_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26 29.25H39"
                stroke="url(#paint3_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M32.5 55.25H45.5"
                stroke="url(#paint4_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M26 71.5H39"
                stroke="url(#paint5_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M71.5 71.5H78"
                stroke="url(#paint6_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M71.5 55.25H78"
                stroke="url(#paint7_linear_588_4309)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_588_4309"
                  x1="52"
                  y1="0"
                  x2="52"
                  y2="104"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_588_4309"
                  x1="35.75"
                  y1="13"
                  x2="35.75"
                  y2="87.75"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_588_4309"
                  x1="74.75"
                  y1="39"
                  x2="74.75"
                  y2="87.75"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_588_4309"
                  x1="32.5"
                  y1="29.25"
                  x2="32.5"
                  y2="30.25"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_588_4309"
                  x1="39"
                  y1="55.25"
                  x2="39"
                  y2="56.25"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_588_4309"
                  x1="32.5"
                  y1="71.5"
                  x2="32.5"
                  y2="72.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_588_4309"
                  x1="74.75"
                  y1="71.5"
                  x2="74.75"
                  y2="72.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_588_4309"
                  x1="74.75"
                  y1="55.25"
                  x2="74.75"
                  y2="56.25"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop
                    offset="0.859"
                    stop-color="#CCAC00"
                    stop-opacity="0.8"
                  />
                  <stop offset="1" stop-color="#CCAC00" stop-opacity="0" />
                </linearGradient>
              </defs>
            </svg>
          <div className="flex flex-col space-y-2 justify-center  w-2/5 sm:w-1/4 animate-zoom-in delay-1100">
            <h1 className="text-xl sm:text-2xl text-black font-semibold">
              Our Misson
            </h1>
            <h1 className="text-sm sm:text-base md:text-xl text-black font-light text-left">
            Distinctively re-engineer revolutionary meta-services and premium  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
