import React from "react";
import prope from "../assets/properties.png";

const PropertiesComponent = () => {
  return (
    <div className="bg-slate-100 rounded-md">
      <img
        src={prope}
        alt="properties"
        className="bg-center bg-cover w-full rounded-md"
      />
      <div className="flex justify-between items-center mt-2 mx-2">
        <h1 className="font-semibold text-2xl ">RAK Facility</h1>
        <h1 className="font-bold text-2xl text-yellow-400">50,000rs</h1>
      </div>
      <div className="flex justify-between items-center mt-1 mx-2">
        <h1 className="font-semibold text-lg flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-yellow-500 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              fill="white"
              className="fill-white"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          Davanhalli, Bengaluru, 560100
        </h1>
        <h1 className="font-semibold text-lg text-black">Advance booking</h1>
      </div>
      <div className="flex justify-between items-center space-x-6 mt-5 mx-2">
        <button className="bg-yellow-500 text-black text-xl font-semibold rounded-full w-1/2 h-12">
          Book Now
        </button>
        <button className="bg-white border-2 border-yellow-500 text-black text-xl font-semibold rounded-full w-1/2 h-12">
          View in VR
        </button>
      </div>
      <div className="grid grid-cols-4 gap-4 my-3 mx-2">
        <button className="bg-white border-2 border-black text-black font-semibold rounded-md flex justify-center items-center p-2">
          <svg
            className="h-6 w-6 text-black mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
            <circle cx="7" cy="10" r="1" />
          </svg>
          3 Beds
        </button>

        <button className="bg-white border-2 border-black text-black font-semibold rounded-md flex justify-center items-center p-2">
          <svg
            width="21"
            height="18"
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.59961 15V17"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.5996 15V17"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.59961 8V2.94444C4.59961 2.42875 4.75764 1.93417 5.03895 1.56951C5.32025 1.20486 5.70178 1 6.09961 1C6.49743 1 6.87897 1.20486 7.16027 1.56951C7.44157 1.93417 7.59961 2.42875 7.59961 2.94444"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5996 7H11.5996V10H16.5996V7Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.3853 8H18.9568C19.1272 8 19.2908 8.06705 19.4113 8.18639C19.5319 8.30573 19.5996 8.46759 19.5996 8.63636V11.1818C19.5996 12.1945 19.1932 13.1656 18.4699 13.8817C17.7465 14.5977 16.7654 15 15.7425 15H5.45675C4.43377 15 3.45269 14.5977 2.72934 13.8817C2.00599 13.1656 1.59961 12.1945 1.59961 11.1818V8.63636C1.59961 8.46759 1.66734 8.30573 1.7879 8.18639C1.90846 8.06705 2.07197 8 2.24247 8H11.2425"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          2 Baths
        </button>

        <button className="bg-white border-2 border-black text-black font-semibold rounded-md flex justify-center items-center p-2">
          <svg
            className="h-8 w-8 text-black mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="7" cy="17" r="2" />
            <circle cx="17" cy="17" r="2" />
            <path d="M5 17h-2v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0h-6m-6 -6h15m-6 0v-5" />
          </svg>
          1 Parking
        </button>

        <button className="bg-white border-2 border-black text-black font-semibold rounded-md flex justify-center items-center p-2">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2544 1.47756L1.7569 12.975C1.48612 13.2458 1.48612 13.6848 1.7569 13.9556L6.32341 18.5221C6.59419 18.7929 7.03322 18.7929 7.304 18.5221L18.8015 7.02466C19.0723 6.75388 19.0723 6.31485 18.8015 6.04407L14.235 1.47756C13.9642 1.20678 13.5252 1.20678 13.2544 1.47756Z"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.1094 4.26855L13.5702 6.72935"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          5000sq.ft
        </button>
      </div>
    </div>
  );
};

export default PropertiesComponent;
