import React from "react";
import prope from "../assets/plot_component.png";
import { Link } from "react-router-dom";

const PlotsComponent = () => {
  return (
    <div className="bg-slate-100 rounded-md">
      <img
        src={prope}
        alt="properties"
        className="bg-center bg-cover w-full rounded-md"
      />
      <div className="flex justify-between items-center mt-2 mx-2">
        <h1 className="font-semibold text-2xl ">RAK Facility</h1>
        <h1 className="font-bold text-2xl text-yellow-400">50,000rs</h1>
      </div>
      <div className="flex justify-between items-center mt-1 mx-2">
        <h1 className="font-semibold text-lg flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-yellow-500 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              fill="white"
              className="fill-white"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
          Davanhalli, Bengaluru, 560100
        </h1>
        <h1 className="font-semibold sm:text-lg text-black">Advance booking</h1>
      </div>

      <div className="flex justify-between items-center mt-3 mx-2"><h1 className="font-semibold sm:text-xl text-black">Residential Plots</h1></div>
      
      <div className="grid grid-cols-3 gap-4 my-3 mx-2">
        <div className="flex flex-col px-2">
          <h1 className="text-black font-semibold ">Unit size:</h1>
          <h1 className="text-black sm:font-normal ">1680 SFT Onwards*</h1>
        </div>
        <div className="flex flex-col border-l border-black px-2">
          <h1 className="text-black font-semibold ">Price:</h1>
          <h1 className="text-black sm:font-normal ">4.80 Cr Onwards*</h1>
        </div>
        <div className="flex flex-col border-l border-black px-2">
          <h1 className="text-black font-semibold ">Status:</h1>
          <h1 className="text-black sm:font-normal ">Ready to Buy*</h1>
        </div>
      </div>

      <div className="flex justify-between items-center space-x-6 my-5 mx-2">
        <button className="bg-yellow-500 text-black sm:text-xl font-semibold rounded-full w-1/2 h-12">
        <Link to="/plotdetailspage">View Project</Link>
        </button>
        <button className="bg-white border-2 border-yellow-500 text-black sm:text-xl font-semibold rounded-full w-1/2 h-12">
          Download Brouchure
        </button>
      </div>
    </div>
  );
};

export default PlotsComponent;
