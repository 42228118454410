import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import default carousel styles

import carousel1 from "../assets/Carousel1.png";

const ProjectsPage = () => {
  // State to track the active slide and center mode
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const projects = [
    {
      id: 1,
      title: "Project 1",
      imageUrl: carousel1,
      link: "/home",
    },
    {
      id: 2,
      title: "Project 2",
      imageUrl: carousel1,
      link: "/home",
    },
    {
      id: 3,
      title: "Project 3",
      imageUrl: carousel1,
      link: "/home",
    },
  ];

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  // Check screen width to toggle center mode for mobile devices
  useEffect(() => {
    const updateMobileMode = () => {
      setIsMobile(window.innerWidth < 768); // Set mobile for screens less than 768px
    };
    window.addEventListener("resize", updateMobileMode);
    updateMobileMode(); // Set initial state based on screen width
    return () => window.removeEventListener("resize", updateMobileMode);
  }, []);

  return (
    <div>
      <NavBar />

      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Heading with Slide-up Animation */}
        <h1 className="font-medium text-3xl sm:text-4xl md:text-5xl font-serif text-center animate-slide-up">
        Projects
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          {/* Paragraph with Fade-in and Delay */}
          <h1 className="mt-6 w-11/12 sm:w-4/5 md:w-3/5 text-center text-base sm:text-lg md:text-xl font-light animate-fade-in delay-200">
          Other projects by us in different locationsentertainment Getting around is a breeze, with easy access to freeways, buses and trolleys. . Laundry is available on premises. 
          </h1>
        </div>

        {/* Images with Zoom-in Animation */}
        <div className="mt-10 flex flex-wrap justify-center gap-10 sm:gap-20 lg:gap-28 w-full sm:mb-10">
          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-300">
            <img src="../assets/plots.png" alt="plots" className="rounded-md w-full h-auto" />
            <h1 className="absolute text-3xl sm:text-4xl font-sans font-semibold text-white"> <Link to="/plotspage">Plots</Link></h1>
          </div>

          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-400">
            <img src="../assets/villas.png" alt="villas" className="rounded-md w-full h-auto" />
            <h1 className="absolute text-3xl sm:text-4xl font-sans font-semibold text-white"><Link to="/villaspage">Villas</Link></h1>
          </div>
        </div>

        <div className="mt-10 flex flex-wrap justify-center gap-10 sm:gap-20 lg:gap-28 w-full sm:mb-10">
          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-500">
            <img src="../assets/apartments.png" alt="apartments" className="rounded-md w-full h-auto" />
            <h1 className="absolute text-3xl sm:text-4xl font-sans font-semibold text-white">
              <Link to="/properties">Apartments</Link>
            </h1>
          </div>

          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-600">
            <img src="../assets/commercials.png" alt="commercials" className="rounded-md w-full h-auto" />
            <h1 className="absolute text-3xl sm:text-4xl font-sans font-semibold text-white">Commercials</h1>
          </div>

          <div className="relative flex flex-col rounded-md justify-center items-center animate-zoom-in delay-700">
            <img src="../assets/resorts.png" alt="resorts" className="rounded-md w-full h-auto" />
            <h1 className="absolute text-3xl sm:text-4xl font-sans font-semibold text-white">Resorts</h1>
          </div>
        </div>
      </div>
      
      {/* Header Section with Animations */}
      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Title with Slide-up Animation */}
        <h1 className="font-medium text-3xl sm:text-4xl md:text-5xl font-serif text-center animate-slide-up">
          Other Projects
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          {/* Subtitle with Fade-in Animation */}
          <h1 className="mt-6 w-11/12 sm:w-4/5 md:w-3/5 text-center text-base sm:text-lg md:text-xl font-light animate-fade-in delay-200">
            Other projects by us in different locations. Getting around is a
            breeze, with easy access to freeways, buses, and trolleys. Laundry
            is available on premises.
          </h1>
        </div>
      </div>

      {/* Image Carousel with Animations */}
      <div className="w-full mt-10 px-4 sm:px-12 lg:px-32 animate-fade-in delay-300 ">
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          centerMode={!isMobile} // Disable centerMode on mobile
          centerSlidePercentage={60} // Show 60% width for the center image
          dynamicHeight={false}
          onChange={handleSlideChange}
        >
          {projects.map((project, index) => (
            <Link to={project.link} key={project.id}>
              <div
                className={`carousel-item ${
                  index === activeIndex ? "active-slide" : ""
                } animate-zoom-in delay-${index * 100}`}
              >
                <img
                  src={project.imageUrl}
                  alt={project.title}
                  className="carousel-image"
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </div>

      <Footer />
    </div>
  );
};

export default ProjectsPage;
