import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import heroImage from "../assets/hero-image.png";
import heroImage1 from "../assets/properties.png";
import heroImage2 from "../assets/plot_component.png";

const images = [heroImage, heroImage2, heroImage1, heroImage2];

const PlotDetailsPage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let interval;
    if (isHovered) {
      // Start cycling images every 1 second when hovered
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2000); // Adjust the time interval (in ms) as needed
    } else {
      // Reset to the first image when not hovered
      setCurrentImageIndex(0);
    }

    // Cleanup interval when component is unmounted or hover state changes
    return () => clearInterval(interval);
  }, [isHovered]);

  return (
    <div className="bg-white font-sans">
      {/* Header Section */}
      <NavBar />
      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Property Overview Section */}
        <section className="text-center">
          <h2 className="text-4xl  font-serif mb-2">RAK Felicity</h2>
          <h2 className="text-xl font-normal font-serif mb-2">Phase I</h2>

          <div className="w-full relative justify-center flex flex-col mb-10">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          <p className="text-gray-700 mt-4 sm:text-lg mx-10 sm:mx-16">
            RAK Felicity is a beautiful Villa plot community in Devanahalli
            surrounded by Nature with delightful amenities. The location is just
            a short drive from the city & main highway, but far enough to enjoy
            the peace and privacy. RAK Felicity is located just 10 mins from
            Bengaluru International Airport which is a prime location. This is
            your chance to live a luxurious life. RAK Felicity by Vencer offers
            you RERA approved Villa plots with amenities like Kid’s Play area,
            Jogging track, Yoga deck, Senior Citizen heaven, Outdoor Gym, Open
            lawn area, Swing park, 24/7 CCTV, Underground recharging & Cycling
            track.
          </p>
          <div className="grid gap-10 sm:flex justify-center items-center sm:space-x-8 mt-8">
            <div>
              <div className="text-center border-yellow-500 border-2 rounded-full w-32 h-32 justify-center items-center flex">
                <svg
                  fill="#000000"
                  width="80"
                  viewBox="0 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>home-building</title>{" "}
                    <path d="M30 1.25h-16c-0.414 0-0.75 0.336-0.75 0.75v0 16c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-15.25h14.5v26.5h-11.25c-0.414 0-0.75 0.336-0.75 0.75s0.336 0.75 0.75 0.75v0h12c0.414-0 0.75-0.336 0.75-0.75v0-28c-0-0.414-0.336-0.75-0.75-0.75v0zM17 8.75h3c0.414-0 0.75-0.336 0.75-0.75v0-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0 3c0 0.414 0.336 0.75 0.75 0.75v0zM17.75 5.75h1.5v1.5h-1.5zM24 8.75h3c0.414-0 0.75-0.336 0.75-0.75v0-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0 3c0 0.414 0.336 0.75 0.75 0.75v0zM24.75 5.75h1.5v1.5h-1.5zM16.25 14c0 0.414 0.336 0.75 0.75 0.75h3c0.414-0 0.75-0.336 0.75-0.75v0-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0zM17.75 11.75h1.5v1.5h-1.5zM23.25 14c0 0.414 0.336 0.75 0.75 0.75h3c0.414-0 0.75-0.336 0.75-0.75v0-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0zM24.75 11.75h1.5v1.5h-1.5zM20.75 20v-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0 3c0 0.414 0.336 0.75 0.75 0.75h3c0.414-0 0.75-0.336 0.75-0.75v0zM19.25 19.25h-1.5v-1.5h1.5zM23.25 20c0 0.414 0.336 0.75 0.75 0.75h3c0.414-0 0.75-0.336 0.75-0.75v0-3c-0-0.414-0.336-0.75-0.75-0.75h-3c-0.414 0-0.75 0.336-0.75 0.75v0zM24.75 17.75h1.5v1.5h-1.5zM8.988 16.431c-0.13-0.112-0.301-0.18-0.488-0.18s-0.358 0.068-0.489 0.181l0.001-0.001-6.5 5.571c-0.161 0.139-0.262 0.343-0.262 0.57v0 7.428c0 0.414 0.336 0.75 0.75 0.75h13c0.414-0 0.75-0.336 0.75-0.75v0-7.428c-0-0.228-0.101-0.431-0.261-0.57l-0.001-0.001zM6.929 29.25v-2.109c0.001-0.827 0.67-1.497 1.497-1.498h0.149c0.827 0.001 1.496 0.671 1.497 1.498v2.109zM14.25 29.25h-2.679v-2.109c-0.002-1.655-1.343-2.995-2.997-2.998h-0.149c-1.655 0.003-2.995 1.343-2.997 2.998v2.11h-2.679v-6.332l5.75-4.93 5.75 4.93z"></path>{" "}
                  </g>
                </svg>
              </div>
              <h3 className="text-xl font-bold">80</h3>
              <p className="text-gray-600">Villa Plots</p>
            </div>

            <div>
              <div className="text-center border-yellow-500 border-2 rounded-full w-32 h-32 justify-center items-center flex">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  width="80"
                >
                  <path d="M5 3 H19 A2 2 0 0 1 21 5 V19 A2 2 0 0 1 19 21 H5 A2 2 0 0 1 3 19 V5 A2 2 0 0 1 5 3 z" />
                  <path d="M3 9h18M9 21V9" />
                </svg>
              </div>
              <h3 className="text-xl font-bold">30</h3>
              <p className="text-gray-600">Acres Layout</p>
            </div>

            <div>
              <div className="text-center border-yellow-500 border-2 rounded-full w-32 h-32 justify-center items-center flex">
                <svg viewBox="0 0 22 22" fill="currentColor" width="80">
                  <path d="M2.59 13.41A1.98 1.98 0 012 12V7a5 5 0 015-5h4.99c.53 0 1.04.2 1.42.59l8 8a2 2 0 010 2.82l-8 8a2 2 0 01-2.82 0l-8-8zM20 12l-8-8H7a3 3 0 00-3 3v5l8 8 8-8zM7 8a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold">2.32 Cr</h3>
              <p className="text-gray-600">Starting Price</p>
            </div>

            <div>
              <div className="text-center border-yellow-500 border-2 rounded-full w-32 h-32 justify-center items-center flex">
                <svg viewBox="0 0 24 24" fill="currentColor" width="80">
                  <path d="M5.64 16.36a9 9 0 1112.72 0l-5.65 5.66a1 1 0 01-1.42 0l-5.65-5.66zm11.31-1.41a7 7 0 10-9.9 0L12 19.9l4.95-4.95zM12 14a4 4 0 110-8 4 4 0 010 8zm0-2a2 2 0 100-4 2 2 0 000 4z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold">30+</h3>
              <p className="text-gray-600">Luxurious Amenities</p>
            </div>
          </div>
          <div className="flex justify-center mt-8 space-x-4">
            <button className="bg-yellow-500 text-black py-2 px-6 rounded hover:bg-yellow-400">
              Floor Plans
            </button>
            <button className="border border-yellow-500 text-yellow-500 py-2 px-6 rounded hover:bg-yellow-500 hover:text-black">
              Master Plan
            </button>
          </div>
        </section>

        {/* Floor Plans Section */}
        <section className="p-8 text-center">
          <h2 className="text-2xl font-bold">Floor Plan</h2>
          <div className="flex flex-col md:flex-row items-center justify-center mt-8 space-x-4">
            <img
              src={heroImage} // Replace with actual image source
              alt="Floor Plan"
              className="w-full md:w-1/2"
            />
            <div className="p-4 border border-yellow-500 bg-white shadow-lg">
              <p>
                <strong>Configuration Type:</strong> Empty Plots
              </p>
              <p>
                <strong>Super Built-up Area:</strong> 2712 to 3953 SFT
              </p>
              <p>
                <strong>Price:</strong> 2.30 Cr Onwards
              </p>
              <div className="mt-4">
                <button className="bg-yellow-500 text-black py-2 px-6 rounded hover:bg-yellow-400">
                  Unlock Price
                </button>
                <button className="border border-yellow-500 text-yellow-500 py-2 px-6 rounded ml-4 hover:bg-yellow-500 hover:text-black">
                  Continue Booking
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* Property Gallery */}
        <section className="p-8 text-center w-full">
          <h2 className="text-4xl font-serif mb-4">Property Gallery</h2>

          <div className="w-full relative justify-center flex flex-col mb-10">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          <div className="mt-8 ">
            <img
              src={images[currentImageIndex]} // Current image based on state
              alt="Property Gallery"
              className="w-full md:w-3/4 mx-auto transition duration-500 ease-in-out transform hover:scale-105"
              onMouseEnter={() => setIsHovered(true)} // Start cycling on hover
              onMouseLeave={() => setIsHovered(false)} // Stop cycling when mouse leaves
            />
          </div>
        </section>

        {/* Amenities Section */}
        <section className="p-8  text-center w-full">
          <h2 className="text-4xl  font-serif mb-4">Aminities</h2>

          <div className="w-full relative justify-center flex flex-col mb-10">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          <div className="flex justify-center mt-8 flex-wrap">
            {[
              "Yoga Deck",
              "Jogging Track",
              "Kids Play Area",
              "Meditation Hall",
              "Outdoor Gym",
              "24/7 CCTV",
              "Senior Citizen Haven",
              "Cycling Track",
              "Garden with Sit-out",
            ].map((amenity) => (
              <div
                key={amenity}
                className="flex flex-col items-center w-1/3 md:w-1/5 mb-8"
              >
                <div className="h-60 w-60 border-2 border-yellow-500 rounded flex flex-col items-center justify-center text-white text-2xl">
                  {/* Placeholder Icon */}
                  🏡
                  <p className="mt-2 text-gray-600">{amenity}</p>
                </div>
               
              </div>
            ))}
          </div>
        </section>
      </div>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default PlotDetailsPage;
