import React from "react";
import NavBar from "../components/NavBar";
import svgMap from "../assets/map.svg";


const test= () => {
  return (
    <div>
      <NavBar /><div className="w-1/2 h-1/2">
      <img src={svgMap} alt="svgMap"/>
  
      
      </div>
    </div>
  );
};

export default test;
