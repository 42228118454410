import React from "react";
import NavBarHome from "../components/NavBarHome";
import Footer from "../components/Footer";
import wp from "../assets/wp.png";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div
      className="relative bg-cover bg-center h-screen"
      style={{ backgroundImage: "url(assets/hero-image.png)" }}
    >
      <div className="bg-black w-full h-full bg-opacity-80">
        <NavBarHome />

        {/* Apply flex and centering only on mobile view */}
        <div className="flex flex-col justify-center h-full px-6 md:block md:px-32 md:py-32 md:pl-32">
          {/* Heading with Fade-in and Slide-up Effect */}
          <h1 className="text-yellow-400 text-3xl sm:text-5xl md:text-6xl lg:text-8xl font-semibold w-full md:w-7/12 text-left animate-slide-up">
            The best place to find your dream house
          </h1>

          {/* Paragraph with Fade-in and Delay */}
          <p className="text-white font-normal text-base sm:text-lg md:text-2xl lg:text-3xl leading-normal w-full md:w-7/12 mt-4 sm:mt-6 md:mt-10 text-left animate-fade-in delay-200">
            Thoughtfully designed with an array of amenities, these homes mark a new
            beginning in community living. Come, immerse yourself in the vibrant
            world of Brooklyn Towers.
          </p>

          {/* Button with Zoom-in Effect */}
          <Link to="/projects">
            <button className="bg-yellow-500 text-gray-900 px-4 py-2 sm:px-6 sm:py-3 rounded-full mt-6 sm:mt-8 transition duration-300 ease-in-out transform hover:bg-yellow-600 hover:scale-110 animate-zoom-in delay-400">
              Book Now
            </button>
          </Link>

          {/* WhatsApp Chat Button */}
          <div className="fixed bottom-5 right-5 flex items-center group">
            <div
              id="whatsapp"
              className="h-7 px-2 sm:px-4 text-black rounded-full mr-2 sm:mr-3 bg-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              Hello There, chat with us!
            </div>
            <Link to="https://wa.me/9158915635">
              <img
                src={wp}
                alt="whatsapp logo"
                className="h-12 sm:h-14 md:h-16 bg-center align-middle transition duration-300 ease-in-out transform hover:scale-110"
              />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
