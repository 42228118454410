import React from "react";

const BookingConfirmation = ({ bookingId, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="w-full sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-md rounded">
        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">
          Booking Confirmed!
        </h2>
        <p className="text-sm sm:text-base md:text-lg">
          Your booking has been successfully completed.
        </p>
        <p className="text-sm sm:text-base md:text-lg">
          Your Booking ID: <strong>{bookingId}</strong>
        </p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white py-2 px-4 rounded text-sm sm:text-base md:text-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmation;
