import React from "react";
import logo from "../assets/RAK_logo.png"; // Assuming you have the logo in your assets
import heroImage from "../assets/hero-image.png"; // Background image if needed

const VillaDetailsPage = () => {
  return (
    <div className="bg-white font-sans">
      {/* Header Section */}
      <header className="flex justify-between items-center p-4 bg-black text-white">
        <div className="flex items-center space-x-4">
          <img src={logo} alt="RAK Logo" className="h-12" />
          <h1 className="text-2xl font-bold">RAK Felicity</h1>
        </div>
        <nav className="space-x-6">
          <a href="#home" className="hover:text-yellow-300">Home</a>
          <a href="#about" className="hover:text-yellow-300">About</a>
          <a href="#projects" className="hover:text-yellow-300">Projects</a>
          <a href="#team" className="hover:text-yellow-300">Our Team</a>
          <a href="#contact" className="hover:text-yellow-300">Enquire Now</a>
        </nav>
        <button className="bg-yellow-500 text-black py-2 px-6 rounded hover:bg-yellow-400">
          Login
        </button>
      </header>

      {/* Property Overview Section */}
      <section className="p-8 text-center">
        <h2 className="text-3xl font-bold">RAK Felicity Phase I</h2>
        <p className="text-gray-700 mt-4">
          RAK Felicity is a beautiful villa plot community in Devanahalli
          surrounded by nature with delightful amenities. Located just 10 mins
          from Bengaluru International Airport, RAK Felicity offers RERA
          approved villa plots with a luxurious lifestyle. The amenities
          include Kid's play area, jogging track, yoga deck, and more.
        </p>
        <div className="flex justify-center space-x-8 mt-8">
          <div className="text-center">
            <h3 className="text-xl font-bold">80</h3>
            <p className="text-gray-600">Villa Plots</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-bold">30</h3>
            <p className="text-gray-600">Acres Layout</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-bold">2.32 Cr</h3>
            <p className="text-gray-600">Starting Price</p>
          </div>
          <div className="text-center">
            <h3 className="text-xl font-bold">30+</h3>
            <p className="text-gray-600">Luxurious Amenities</p>
          </div>
        </div>
        <div className="flex justify-center mt-8 space-x-4">
          <button className="bg-yellow-500 text-black py-2 px-6 rounded hover:bg-yellow-400">
            Floor Plans
          </button>
          <button className="border border-yellow-500 text-yellow-500 py-2 px-6 rounded hover:bg-yellow-500 hover:text-black">
            Master Plan
          </button>
        </div>
      </section>

      {/* Floor Plans Section */}
      <section className="p-8 bg-gray-100 text-center">
        <h2 className="text-2xl font-bold">Floor Plan</h2>
        <div className="flex flex-col md:flex-row items-center justify-center mt-8 space-x-4">
          <img
            src={heroImage} // Replace with actual image source
            alt="Floor Plan"
            className="w-full md:w-1/2"
          />
          <div className="p-4 border border-yellow-500 bg-white shadow-lg">
            <p><strong>Configuration Type:</strong> Empty Plots</p>
            <p><strong>Super Built-up Area:</strong> 2712 to 3953 SFT</p>
            <p><strong>Price:</strong> 2.30 Cr Onwards</p>
            <div className="mt-4">
              <button className="bg-yellow-500 text-black py-2 px-6 rounded hover:bg-yellow-400">
                Unlock Price
              </button>
              <button className="border border-yellow-500 text-yellow-500 py-2 px-6 rounded ml-4 hover:bg-yellow-500 hover:text-black">
                Continue Booking
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Property Gallery */}
      <section className="p-8 text-center">
        <h2 className="text-2xl font-bold">Property Gallery</h2>
        <div className="mt-8">
          <img
            src={heroImage} // Replace with actual image source
            alt="Property Gallery"
            className="w-full md:w-3/4 mx-auto"
          />
        </div>
      </section>

      {/* Amenities Section */}
      <section className="p-8 bg-gray-100 text-center">
        <h2 className="text-2xl font-bold">Amenities</h2>
        <div className="flex justify-center mt-8 space-x-4 flex-wrap">
          {[
            "Yoga Deck",
            "Jogging Track",
            "Kids Play Area",
            "Meditation Hall",
            "Outdoor Gym",
            "24/7 CCTV",
            "Senior Citizen Haven",
            "Cycling Track",
            "Garden with Sit-out",
          ].map((amenity) => (
            <div key={amenity} className="flex flex-col items-center w-1/3 md:w-1/5 mb-4">
              <div className="h-16 w-16 bg-yellow-500 rounded-full flex items-center justify-center text-white text-2xl">
                {/* Placeholder Icon */}
                🏡
              </div>
              <p className="mt-2 text-gray-600">{amenity}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Footer Section */}
      <footer className="bg-black text-white p-8 text-center">
        <div className="flex justify-center space-x-12 mb-4">
          <div>
            <h3 className="text-lg font-bold">RAK Groups</h3>
            <p>Bengaluru, 560100</p>
          </div>
          <div>
            <h3 className="text-lg font-bold">Contact Us</h3>
            <p>📞 123-456-7890</p>
            <p>📧 rakgroups@gmail.com</p>
          </div>
        </div>
        <p>&copy; 2024 RAK Groups | All Rights Reserved</p>
      </footer>
    </div>
  );
};

export default VillaDetailsPage;
