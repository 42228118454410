import React, { useEffect } from "react";

const ToastMsg = ({ duration = 3000, onClose, notificationMsg }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, duration);

        return () => clearTimeout(timer); // cleanup the timer on component unmount
    }, [duration, onClose]);

    return (
        <aside className="fixed z-50 flex items-center justify-center px-5 py-3 text-white bg-black rounded-lg top-4 right-4">
            <span className="text-xl font-medium">{notificationMsg}</span>

            <button onClick={onClose} className="p-1 ml-3 rounded bg-white/20 hover:bg-white/10">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd" />
                </svg>
            </button>
        </aside>
    )
}

export default ToastMsg;