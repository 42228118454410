import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import TeamProfile from "../components/TeamProfile";

const TeamPage = () => {
  return (
    <div>
      <NavBar />

      {/* Header Section with Animations */}
      <div className="mx-4 sm:mx-12 md:mx-20 lg:mx-32 flex flex-col justify-center items-center mt-10 sm:mt-16 animate-fade-in">
        {/* Title with Slide-up Animation */}
        <h1 className="font-medium text-3xl sm:text-4xl md:text-5xl font-serif text-center animate-slide-up">
          Our Property Team
        </h1>

        <div className="w-full flex flex-col justify-center items-center mt-6">
          <div className="w-full relative justify-center flex flex-col">
            <hr className="bg-gray-400 h-0.5 w-full absolute" />
            {/* Animated SVG circles */}
            <svg
              width="74"
              height="17"
              viewBox="0 0 74 17"
              fill="none"
              className="absolute animate-slide-in-right"
            >
              <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
              <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
              <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
              <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
              <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
            </svg>
          </div>

          {/* Subtitle with Fade-in Animation */}
          <h1 className="mt-6 w-11/12 sm:w-4/5 md:w-3/5 text-center text-base sm:text-lg md:text-xl font-light animate-fade-in delay-200">
            Other projects by us in different locations. Entertainment is easily
            accessible with proximity to freeways, buses, and trolleys. Laundry
            services are available on premises.
          </h1>
        </div>

        {/* Team Profiles with Zoom-in Animation */}
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 justify-between w-full mb-14 animate-fade-in delay-300">
          {/* Each profile has a zoom-in animation */}
          <div className="animate-zoom-in delay-100">
            <TeamProfile />
          </div>
          <div className="animate-zoom-in delay-200">
            <TeamProfile />
          </div>
          <div className="animate-zoom-in delay-300">
            <TeamProfile />
          </div>
          <div className="animate-zoom-in delay-400">
            <TeamProfile />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TeamPage;
