import React from "react";
import image from "../assets/image.png";

const TeamProfile = () => {
  return (
    <div className="group bg-slate-100 hover:bg-yellow-400 hover:scale-105 rounded-md transition duration-300 delay-150 w-full sm:w-64 lg:w-full">
      <img
        src={image}
        alt="profile"
        className="w-full h-48 object-cover rounded-t-md"
      />
      <div className="p-4">
        <h1 className="font-semibold text-xl sm:text-2xl">Peter Parker</h1>
        <div className="flex justify-between items-center mt-2">
          <h1 className="text-yellow-500 group-hover:text-white transition duration-300">
            Land Seller
          </h1>
          <div className="flex space-x-2">
            <a
              href="www.instagram.com"
              className="h-8 w-8 flex items-center justify-center"
            >
              <svg
                className="h-6 w-6 text-black"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </a>

            <a
              href="www.twitter.com"
              className="h-8 w-8 flex items-center justify-center"
            >
              <svg
                className="h-6 w-6 text-black"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
              </svg>
            </a>

            <a
              href="www.youtube.com"
              className="h-8 w-8 flex items-center justify-center"
            >
              <svg
                className="h-6 w-6 text-black"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamProfile;
