import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/RAK_logo.png";
import { useAuth } from "../context/AuthContext";

const NavBarHome = () => {
  const { isAuthenticated, userRole } = useAuth();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const getDashboardLink = () => {
    if (userRole === "user") {
      return "/user-dashboard";
    } else if (userRole === "agent") {
      return "/agent-dashboard";
    } else if (userRole === "owner") {
      return "/owner-dashboard";
    } else {
      return "/home";
    }
  };

  return (
    <nav className="h-16 sm:h-20 content-center bg-black p-4 text-white bg-opacity-0">
      <div className="h-full flex justify-between items-center px-6 sm:px-16 md:px-28">
        {/* Logo Section */}
        <div>
          <Link to="/home">
            <img src={logo} alt="RAK LOGO" className="bg-center h-10 sm:h-12 md:h-16" />
          </Link>
        </div>

        {/* Hamburger Menu Icon for Mobile */}
        <div className="sm:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Navigation Links and Authentication */}
        <div className="hidden sm:flex items-center space-x-6 sm:space-x-8 md:space-x-16">
          {/* Navigation Menu */}
          <ul className="flex space-x-4 md:space-x-8 lg:space-x-16">
            <li>
              <Link to="/home" className="hover:text-yellow-300">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="hover:text-yellow-300">
                About
              </Link>
            </li>
            <li>
              <Link to="/projects" className="hover:text-yellow-300">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/team" className="hover:text-yellow-300">
                Our Team
              </Link>
            </li>
            <li>
              <Link to="/enquire" className="hover:text-yellow-300">
                Enquire Now
              </Link>
            </li>
          </ul>

          {/* Authenticated Section */}
          {isAuthenticated ? (
            <div className="flex items-center space-x-2 sm:space-x-4">
              <Link to={getDashboardLink()} className="flex items-center space-x-2">
                <img
                  src="../assets/amenities.png"
                  alt="Profile"
                  className="h-8 w-8 sm:h-10 sm:w-10 rounded-full"
                />
                <span className="text-sm sm:text-base">Dashboard</span>
              </Link>
            </div>
          ) : (
            <Link
              to="/login"
              className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-4 sm:px-6 sm:py-2 md:px-8 rounded-full"
            >
              Login
            </Link>
          )}
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="sm:hidden absolute top-16 left-0 w-full bg-black bg-opacity-90 text-white">
            <ul className="flex flex-col space-y-4 p-4">
              <li>
                <Link to="/home" className="hover:text-yellow-300" onClick={toggleMobileMenu}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-yellow-300" onClick={toggleMobileMenu}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/projects" className="hover:text-yellow-300" onClick={toggleMobileMenu}>
                  Projects
                </Link>
              </li>
              <li>
                <Link to="/team" className="hover:text-yellow-300" onClick={toggleMobileMenu}>
                  Our Team
                </Link>
              </li>
              <li>
                <Link to="/enquire" className="hover:text-yellow-300" onClick={toggleMobileMenu}>
                  Enquire Now
                </Link>
              </li>
              {isAuthenticated ? (
                <li>
                  <Link
                    to={getDashboardLink()}
                    className="flex items-center space-x-2"
                    onClick={toggleMobileMenu}
                  >
                    <img
                      src="../assets/amenities.png"
                      alt="Profile"
                      className="h-8 w-8 rounded-full"
                    />
                    <span>Dashboard</span>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    to="/login"
                    className="bg-yellow-500 hover:bg-yellow-400 text-black font-semibold py-2 px-6 rounded-full"
                    onClick={toggleMobileMenu}
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </nav>

  );
};

export default NavBarHome;
