import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { ReactComponent as PlotMapSVG } from "../assets/PlotMap.svg";
import "../styles/tailwind.css";

const PlotMap = ({ onPlotsSelected }) => {
  const [plots, setPlots] = useState([]);
  const [selectedPlots, setSelectedPlots] = useState([]);
  const svgRef = useRef(null);

  const getPlotColor = (status) => {
    switch (status) {
      case "booked":
        return "darkkhaki";
      case "available":
        return "gray";
      case "selected":
        return "green";
      default:
        return "gray";
    }
  };

  const updatePlotColors = useCallback((plots) => {
    if (svgRef.current) {
      plots.forEach(plot => {
        const plotElement = svgRef.current.getElementById(plot._id);
        if (plotElement) {
          plotElement.style.fill = getPlotColor(plot.status);
        }
      });
    }
  }, [svgRef]);

  useEffect(() => {
    const fetchPlots = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plots`
        );
        setPlots(response.data);
        updatePlotColors(response.data);
      } catch (error) {
        console.error("Error fetching plots:", error);
      }
    };
    fetchPlots();
  }, [updatePlotColors]);

  const handlePlotClick = (plotId) => {
    const plot = plots.find((p) => p._id === plotId);

    if (plot) {
      if (plot.status === "booked") {
        return; // Do nothing if the plot is booked
      }
      if (selectedPlots.includes(plotId)) {
        const newSelectedPlots = selectedPlots.filter((id) => id !== plotId);
        setSelectedPlots(newSelectedPlots);
        onPlotsSelected(newSelectedPlots); // Pass selected plots to parent
      } else {
        const newSelectedPlots = [...selectedPlots, plotId];
        setSelectedPlots(newSelectedPlots);
        onPlotsSelected(newSelectedPlots); // Pass selected plots to parent
      }

      updateSelectedPlotColor(plotId);
    } else {
      console.log("plot not available");
    }
  };

  const updateSelectedPlotColor = (plotId) => {
    if (svgRef.current) {
      const plotElement = svgRef.current.getElementById(plotId);
      if (plotElement) {
        plotElement.style.fill = selectedPlots.includes(plotId)
          ? getPlotColor('available')
          : getPlotColor('selected');
      }
    }
  };

  return (
    <div className="relative w-full h-full border overflow-hidden">
      <div className="plot-map w-full h-full flex items-center justify-center">
        <PlotMapSVG
          ref={svgRef}
          className="w-full h-auto max-w-full max-h-full"
          onClick={(e) => {
            if (
              e.target.tagName === "path" ||
              e.target.tagName === "rect" ||
              e.target.tagName === "circle"
            ) {
              handlePlotClick(e.target.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PlotMap;
