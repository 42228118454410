import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import BookingConfirmation from "./BookingConfirmation";

const BookingModal = ({ isOpen, onClose, selectedPlots }) => {
  const [agentId, setAgentId] = useState("");
  const [plotDetails, setPlotDetails] = useState([]);
  const [bookingId, setBookingId] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchPlotDetails = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/plots/details`,
          { plotIds: selectedPlots }
        );
        setPlotDetails(response.data);
      } catch (error) {
        console.error("Error fetching plot details:", error);
      }
    };

    if (isOpen && selectedPlots.length > 0) {
      fetchPlotDetails();
    }
  }, [isOpen, selectedPlots]);

  const showBookingConfirmation = (bookingId) => {
    setBookingId(bookingId); // Set the booking ID to display
  };

  if (!isOpen) return null;

  if (bookingId) {
    return <BookingConfirmation bookingId={bookingId} onClose={onClose} />;
  }

  const bookingChargePerPlot = 50000;
  const totalAmount = plotDetails.length * bookingChargePerPlot;

  const handlePayment = async () => {
    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/createOrder`,
        { amount: totalAmount * 100 } // amount in paise
      );

      const { id: order_id, currency, amount } = orderResponse.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: currency,
        name: "Your Company Name",
        description: "Test Transaction",
        order_id: order_id,
        handler: async function (response) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            plotIds: selectedPlots,
            agentId: agentId,
            userId: user.id,
          };

          const verifyPaymentResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/payment/verifyPayment`,
            paymentData
          );

          if (verifyPaymentResponse.status === 200) {
            const bookingId = order_id;
            onClose();
            showBookingConfirmation(bookingId);
          } else {
            alert("Payment verification failed");
          }
        },
        prefill: {
          name: user.fname,
          email: user.email,
          contact: user.mnumber,
        },
        notes: {
          address: "Some Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Error during payment process. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="w-full sm:max-w-lg md:max-w-2xl lg:max-w-3xl mx-auto p-4 sm:p-6 md:p-8 bg-white shadow-md rounded">
        <h2 className="text-lg sm:text-xl md:text-2xl font-semibold mb-4">Booking Details</h2>

        <div className="w-full relative justify-center flex flex-col mb-4">
          <hr className="bg-gray-400 h-0.5 w-full absolute" />
          <svg
            width="74"
            height="17"
            viewBox="0 0 74 17"
            fill="none"
            className="absolute"
          >
            <circle cx="7" cy="8.5" r="7" fill="#CCAC00" />
            <circle cx="26" cy="8.5" r="6" fill="#CCAC00" />
            <circle cx="43" cy="8.5" r="5" fill="#CCAC00" />
            <circle cx="58" cy="8.5" r="4" fill="#CCAC00" />
            <circle cx="71" cy="8.5" r="3" fill="#CCAC00" />
          </svg>
        </div>

        <div className="mb-4">
          {plotDetails.map((plot, index) => (
            <div key={plot._id} className="flex flex-col sm:flex-row justify-between mb-4">
              <div>
                <h3 className="font-semibold">{plot.name}</h3>
                <p>Property: {plot.name}</p>
                <p>Plot size: {plot.size} sq.ft</p>
                <p>Advance Booking Amt.: Rs. {bookingChargePerPlot.toLocaleString("en-IN")}</p>
              </div>
            </div>
          ))}
          <div className="mt-4 text-right">
            <p className="font-semibold">
              Total Amount: Rs. {totalAmount.toLocaleString("en-IN")}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <input
            type="text"
            placeholder="Full Name"
            value={user.fname} 
            readOnly
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Mobile Number"
            value={user.mnumber}
            readOnly
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Email Address"
            value={user.email}
            readOnly
            className="p-2 border rounded bg-gray-100"
          />
          <input
            type="text"
            placeholder="Agent reference ID"
            value={agentId}
            onChange={(e) => setAgentId(e.target.value)}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </div>

        <div className="flex flex-col sm:flex-row justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white py-2 px-4 rounded mb-2 sm:mb-0 sm:mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handlePayment}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Confirm Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookingModal;
