import React, { useState } from "react";
import axios from "axios";
import { setAuthToken } from "../utils/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../assets/RAK_logo.png";
import ToastMsg from "../components/ToastMsg";

const RegisterPage = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    mnumber: "",
    email: "",
    password: "",
    password2: "",
  });
  const { fname, lname, mnumber, email, password, password2 } = formData;
  const [showToast, setShowToast] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      setError("Passwords do not match.");
      setShowToast(true);
    } else {
      const newUser = { fname, lname, mnumber, email, password };
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/register`,
          newUser
        );
        setAuthToken(res.data.token);
        navigate("/login");
        alert("User Registered, Login into user account.");
      } catch (err) {
        setError("Register Failed, Please try again later.");
        setShowToast(true);
        console.error(err);
      }
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div
      className="relative bg-cover bg-top h-screen overflow-y-auto animate-fade-in"
      style={{ backgroundImage: "url(assets/hero-image.png)" }}
    >
      <div className="h-full flex flex-col lg:flex-row animate-fade-in-up">
        <div className="h-full w-full bg-black bg-opacity-80 flex flex-col animate-fade-in">
          <div className="flex h-20 md:h-36 px-6 md:px-16 lg:px-32 w-full justify-between items-center animate-fade-in-left">
            <div className="text-white text-sm md:text-lg font-semibold animate-fade-in ">
              <Link to="/home">
                <img
                  src={logo}
                  alt="RAK LOGO"
                  className="bg-center h-10 md:h-16 animate-fade-in"
                />
              </Link>
            </div>

            {/* Hamburger Menu Icon */}
            <div className="md:hidden fixed right-6 z-50 animate-fade-in">
              <button onClick={toggleMenu} className="text-white text-3xl">
                {menuOpen ? "×" : "☰"}
              </button>
            </div>

            {/* Navigation Links */}
            <ul
              className={`fixed md:static top-0 right-0 w-2/3 md:w-auto bg-black bg-opacity-90 md:bg-transparent h-full md:h-auto transform ${
                menuOpen ? "translate-x-0" : "translate-x-full"
              } md:translate-x-0 transition-transform duration-300 ease-in-out md:flex space-y-4 md:space-y-0 space-x-0 md:space-x-16 text-white font-semibold p-6 md:p-0 z-40 animate-fade-in-up`}
            >
              <li>
                <Link
                  to="/home"
                  className="hover:text-yellow-300"
                  onClick={closeMenu}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="hover:text-yellow-300"
                  onClick={closeMenu}
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  className="hover:text-yellow-300"
                  onClick={closeMenu}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/team"
                  className="hover:text-yellow-300"
                  onClick={closeMenu}
                >
                  Our Team
                </Link>
              </li>
              <li>
                <Link
                  to="/enquire"
                  className="hover:text-yellow-300"
                  onClick={closeMenu}
                >
                  Enquire Now
                </Link>
              </li>
            </ul>
          </div>

          <div className="flex flex-col h-full justify-center px-6 md:px-16 lg:px-32 py-4 animate-fade-in-up">
            <h1 className="text-yellow-400 text-2xl md:text-5xl lg:text-8xl font-semibold leading-tight animate-fade-in-up">
              The best place to find your dream house
            </h1>
            <h1 className="text-white font-normal text-sm md:text-lg lg:text-3xl leading-normal lg:mr-20 py-4 lg:py-8 animate-fade-in-up">
              Thoughtfully designed with an array of amenities, these homes mark
              a new beginning in community living. Come, immerse yourself in the
              vibrant world of Brooklyn Towers.
            </h1>
            <Link to="/projects">
              <button className="h-10 w-24 md:h-11 md:w-28 rounded-full bg-yellow-400 hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:scale-105 animate-fade-in-up">
                Know More
              </button>
            </Link>
          </div>
        </div>

        <div className="h-full w-full flex justify-center items-center px-4 sm:px-10 lg:px-0 animate-fade-in-up overflow-y-auto">
          <div className="bg-black bg-opacity-80 w-full mt-40 max-w-lg md:max-w-3/5 lg:w-3/5 rounded-3xl flex flex-col items-center p-2 sm:p-6 md:p-8 animate-fade-in flex-grow">
            <h1 className="text-yellow-400 font-bold text-xl md:text-3xl sm:mt-4 md:mt-8 w-full text-center animate-fade-in-up">
              New Register
            </h1>
            <h1 className="text-white font-normal mt-2 sm:mt-4 md:mt-6 text-center px-2 md:px-6 animate-fade-in-up">
              Seamless Access, Elevating Experience. Unlock Your Property
              Journey with RAK Groups
            </h1>
            {error && (
              <p className="text-red-500 text-center animate-fade-in-up">
                {error}
              </p>
            )}
            <div className="w-full animate-fade-in">
              <form
                onSubmit={onSubmit}
                className="w-full flex flex-col sm:mt-6 animate-fade-in-up"
              >
                {/* Input fields */}
                <input
                  type="text"
                  name="fname"
                  value={fname}
                  onChange={onChange}
                  placeholder="First Name"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="text"
                  name="lname"
                  value={lname}
                  onChange={onChange}
                  placeholder="Last Name"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="text"
                  name="mnumber"
                  value={mnumber}
                  onChange={onChange}
                  placeholder="Mobile Number"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Password"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <input
                  type="password"
                  name="password2"
                  value={password2}
                  onChange={onChange}
                  placeholder="Confirm Password"
                  required
                  className="w-full p-2 border rounded-xl bg-black text-white border-zinc-500 autofill:bg-transparent mt-4 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 animate-fade-in-up"
                />
                <button
                  type="submit"
                  className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded-xl mt-6 focus:outline-none focus:ring focus:ring-yellow-400 transition duration-300 ease-in-out transform hover:scale-105 animate-fade-in-up"
                >
                  Register
                </button>
              </form>
              <div className="mt-2 flex justify-center items-center flex-col animate-fade-in-up">
                <p className="text-gray-300">Already have an account?</p>
                <Link to="/login" className="text-yellow-400 hover:underline">
                  Login here
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Toast Message */}
      {showToast && (
        <ToastMsg
          message={error}
          onClose={handleCloseToast}
          className="fixed bottom-4 right-4 animate-fade-in"
        />
      )}
    </div>
  );
};

export default RegisterPage;
