import React from "react";
import "aframe";
import { Entity, Scene } from "aframe-react";
import { useNavigate } from "react-router-dom";
import floor from "../assets/oak.jpg";
import VRimg from "../assets/VR/VR1.jpg";

const VRExperience = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/vr-experience2");
  };

  const handleClickHome = () => {
    navigate("/home");
  };

  return (
    <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
      {/* VR Scene */}
      <Scene embedded vr-mode-ui="enabled: true" renderer="antialias: true; maxPixelRatio: 2">

        <a-assets>
          <img id="groundTexture" src={floor} alt="Ground" crossorigin="anonymous" />
          <img id="skyTexture" src={VRimg} alt="Sky" crossorigin="anonymous" />
        </a-assets>
        <Entity cursor="rayOrigin: mouse" raycaster="objects: .clickable" />
        <Entity
  primitive="a-sky"
  src="#skyTexture"
  rotation="0 0 0"
  material="npot: true; minFilter: linear; magFilter: linear; shader: flat"
></Entity>

        <Entity
          className="clickable"
          geometry={{ primitive: "plane", height: 0.5, width: 2 }}
          material={{ color: "#0000FF", opacity: 0.8 }}
          position="0 2 -4"
          text={{ value: "Way to Parking", align: "center", color: "#FFFFFF" }}
          events={{ click: handleClick }}
        />
      </Scene>

      {/* Corner Button */}
      <button
        onClick={handleClickHome}
        style={{
          position: "absolute",
          top: "10px", // Adjust the position as needed
          right: "10px", // Adjust the position as needed
          zIndex: 1000, // Ensure it's above the VR scene
          padding: "10px 15px",
          backgroundColor: "#007BFF",
          color: "#FFFFFF",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Home
      </button>
    </div>
  );
};

export default VRExperience;
