import React, { useState } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import brochure from "../assets/pdf/brochure.pdf";

const EnquirePage = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState("Interested In");

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleInterestSelect = (interest) => {
    setSelectedInterest(interest);
    setShowDropdown(false);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />

      {/* Main Section with Animation */}
      <div className="flex flex-col lg:flex-row my-20 mx-4 lg:mx-32 animate-fade-in">

        {/* Left section with Slide-up Animation */}
        <div className="lg:w-1/2 flex-col items-center pr-10 lg:h-full animate-slide-up">
          <h1 className="text-black text-4xl lg:text-8xl font-semibold">
            Did You Find Your
          </h1>
          <h1 className="text-yellow-400 text-4xl lg:text-8xl font-semibold">
            Dream Home?
          </h1>
          <h1 className="text-black font-normal text-lg lg:text-3xl leading-normal pt-8 pr-8 lg:pr-80">
            Thank you for getting in touch! If you found your dream home, connect with us.
          </h1>

          {/* Download Brochure Button with Zoom-in Animation */}
          <a
            href={brochure}
            download="Brochure.pdf"
            //className="mt-6 inline-block bg-yellow-400 hover:bg-yellow-500 text-black py-3 px-6 rounded-full text-lg font-semibold animate-zoom-in"
            className="mt-6 inline-block bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-6 rounded-full animate-zoom-in"
          >
            Download Brochure
          </a>
        </div>

        {/* Right section - Form with Animation */}
        <div className="lg:w-1/2 flex-col mt-10 lg:mt-0 animate-slide-in-right">
          <form>
            <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
              {/* Form fields with Fade-in Animation */}
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-4 animate-fade-in"
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-4 animate-fade-in delay-100"
              />
              <input
                type="tel"
                name="mnumber"
                placeholder="Phone Number"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 mt-6 animate-fade-in delay-200"
              />

              {/* Dropdown for Interests with Fade-in Animation */}
              <div className="relative mt-6 animate-fade-in delay-300">
                <button
                  type="button"
                  className="w-full p-2 bg-white text-slate-400 border-black border-b-2 inline-flex justify-between items-center"
                  onClick={toggleDropdown}
                >
                  {selectedInterest}
                  <svg
                    className="w-4 h-4 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                {showDropdown && (
                  <div className="absolute z-10 bg-white border rounded-lg shadow-md mt-2 w-full">
                    <ul className="py-2 text-sm text-gray-700">
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleInterestSelect("Option 1")}
                      >
                        Option 1
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleInterestSelect("Option 2")}
                      >
                        Option 2
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleInterestSelect("Option 3")}
                      >
                        Option 3
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-12">
              <input
                type="text"
                name="message"
                placeholder="Message"
                required
                className="w-full p-2 bg-white text-black border-black border-b-2 animate-fade-in delay-400"
              />
              <button
                type="submit"
                className="bg-yellow-400 hover:bg-yellow-500 text-black py-2 px-4 w-full md:w-1/2 lg:w-3/12 rounded-full mt-12 animate-zoom-in"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EnquirePage;
