import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAuthToken } from "../utils/auth";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const OwnerDashboard = () => {
  const [plots, setPlots] = useState([]);

  const { logout } = useAuth();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = getAuthToken();
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/profile`
        );
        setProfile(response.data);
      } catch (error) {
        console.error(
          "Error fetching user profile:",
          error.response ? error.response.data : error.message
        );
      }
    };

    const fetchPlotsAndAgents = async () => {
      try {
        const token = getAuthToken();
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const plotsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plots/owner`
        );

        setPlots(plotsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response) {
          console.error("Error response:", error.response.data);
        } else {
          console.error("Error message:", error.message);
        }
      }
    };

    fetchUserProfile();
    fetchPlotsAndAgents();
  }, []);

  const profilePictureUrl = profile.profilePicture
    ? `${process.env.REACT_APP_API_URL}/uploads/${profile.profilePicture}`
    : "https://via.placeholder.com/100";

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-64 fixed top-0 left-0 h-full shadow-lg flex flex-col justify-between">
        <div>
          <div className="p-4 border-b w-full md:w-48 mx-auto">
            <Link to="/home" className="block w-fit">
              <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" />
              </svg>
            </Link>

            <div className="w-full h-40 rounded-full overflow-hidden mt-4">
              <img
                src={profilePictureUrl}
                alt="Profile"
                className="w-full h-full object-cover"
              />
            </div>
            <h2 className="mt-2 text-center text-lg font-semibold">
              {profile.fname} {profile.lname}
            </h2>
          </div>

          <div className="p-4">
            <nav className="space-y-2">
              <Link
                to="/owner-dashboard"
                className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13 21V11h8v10h-8zM3 13V3h8v10H3zm6-2V5H5v6h4zM3 21v-6h8v6H3zm2-2h4v-2H5v2zm10 0h4v-6h-4v6zM13 3h8v6h-8V3zm2 2v2h4V5h-4z" />
                </svg>
                Dashboard
              </Link>

              <Link
                to="/owner-dashboard-agent"
                className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M12.5 11.95C12.9833 11.4167 13.3542 10.8083 13.6125 10.125C13.8708 9.44167 14 8.73333 14 8C14 7.26667 13.8708 6.55833 13.6125 5.875C13.3542 5.19167 12.9833 4.58333 12.5 4.05C13.5 4.18333 14.3333 4.625 15 5.375C15.6667 6.125 16 7 16 8C16 9 15.6667 9.875 15 10.625C14.3333 11.375 13.5 11.8167 12.5 11.95ZM18 20V17C18 16.4 17.8667 15.8292 17.6 15.2875C17.3333 14.7458 16.9833 14.2667 16.55 13.85C17.4 14.15 18.1875 14.5375 18.9125 15.0125C19.6375 15.4875 20 16.15 20 17V20H18ZM20 13V11H18V9H20V7H22V9H24V11H22V13H20ZM8 12C6.9 12 5.95833 11.6083 5.175 10.825C4.39167 10.0417 4 9.1 4 8C4 6.9 4.39167 5.95833 5.175 5.175C5.95833 4.39167 6.9 4 8 4C9.1 4 10.0417 4.39167 10.825 5.175C11.6083 5.95833 12 6.9 12 8C12 9.1 11.6083 10.0417 10.825 10.825C10.0417 11.6083 9.1 12 8 12ZM0 20V17.2C0 16.6333 0.145833 16.1125 0.4375 15.6375C0.729167 15.1625 1.11667 14.8 1.6 14.55C2.63333 14.0333 3.68333 13.6458 4.75 13.3875C5.81667 13.1292 6.9 13 8 13C9.1 13 10.1833 13.1292 11.25 13.3875C12.3167 13.6458 13.3667 14.0333 14.4 14.55C14.8833 14.8 15.2708 15.1625 15.5625 15.6375C15.8542 16.1125 16 16.6333 16 17.2V20H0ZM8 10C8.55 10 9.02083 9.80417 9.4125 9.4125C9.80417 9.02083 10 8.55 10 8C10 7.45 9.80417 6.97917 9.4125 6.5875C9.02083 6.19583 8.55 6 8 6C7.45 6 6.97917 6.19583 6.5875 6.5875C6.19583 6.97917 6 7.45 6 8C6 8.55 6.19583 9.02083 6.5875 9.4125C6.97917 9.80417 7.45 10 8 10ZM2 18H14V17.2C14 17.0167 13.9542 16.85 13.8625 16.7C13.7708 16.55 13.65 16.4333 13.5 16.35C12.6 15.9 11.6917 15.5625 10.775 15.3375C9.85833 15.1125 8.93333 15 8 15C7.06667 15 6.14167 15.1125 5.225 15.3375C4.30833 15.5625 3.4 15.9 2.5 16.35C2.35 16.4333 2.22917 16.55 2.1375 16.7C2.04583 16.85 2 17.0167 2 17.2V18Z"
                      fill="#6A6A6A"
                    />
                  </g>
                </svg>
                Agents
              </Link>

              <Link
                to="/owner-dashboard-user"
                className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.53 7.77C17.46 7.76 17.39 7.76 17.32 7.77C15.77 7.72 14.54 6.45 14.54 4.89C14.54 3.3 15.83 2 17.43 2C19.02 2 20.32 3.29 20.32 4.89C20.31 6.45 19.08 7.72 17.53 7.77Z"
                    fill="#CCAC00"
                  />
                  <path
                    d="M20.7896 14.6999C19.6696 15.4499 18.0996 15.7299 16.6496 15.5399C17.0296 14.7199 17.2296 13.8099 17.2396 12.8499C17.2396 11.8499 17.0196 10.8999 16.5996 10.0699C18.0796 9.86991 19.6496 10.1499 20.7796 10.8999C22.3596 11.9399 22.3596 13.6499 20.7896 14.6999Z"
                    fill="#CCAC00"
                  />
                  <path
                    d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
                    fill="#CCAC00"
                  />
                  <path
                    d="M6.55012 12.8501C6.55012 13.8201 6.76012 14.7401 7.14012 15.5701C5.73012 15.7201 4.26012 15.4201 3.18012 14.7101C1.60012 13.6601 1.60012 11.9501 3.18012 10.9001C4.25012 10.1801 5.76012 9.8901 7.18012 10.0501C6.77012 10.8901 6.55012 11.8401 6.55012 12.8501Z"
                    fill="#CCAC00"
                  />
                  <path
                    d="M12.1198 15.87C12.0398 15.86 11.9498 15.86 11.8598 15.87C10.0198 15.81 8.5498 14.3 8.5498 12.44C8.5598 10.54 10.0898 9 11.9998 9C13.8998 9 15.4398 10.54 15.4398 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87Z"
                    fill="#CCAC00"
                  />
                  <path
                    d="M8.8698 17.9399C7.3598 18.9499 7.3598 20.6099 8.8698 21.6099C10.5898 22.7599 13.4098 22.7599 15.1298 21.6099C16.6398 20.5999 16.6398 18.9399 15.1298 17.9399C13.4198 16.7899 10.5998 16.7899 8.8698 17.9399Z"
                    fill="#CCAC00"
                  />
                </svg>
                Customers
              </Link>

              <Link
                to="/owner-settings"
                className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
              >
                <svg
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={32}
                    d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
                  />
                </svg>
                Settings
              </Link>
            </nav>
          </div>
        </div>

        <button
          onClick={logout}
          className="flex p-2 items-center gap-4 rounded hover:bg-gray-200 mb-4 mx-4"
        >
          <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 011-1h14a1 1 0 011 1v18a1 1 0 01-1 1H5a1 1 0 01-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z" />
          </svg>
          Logout
        </button>
      </div>

      {/* Main content */}
      <div className="flex-grow ml-64 p-8">
        <h1 className="text-3xl font-bold mb-6">Booked Plots</h1>

        <table className="min-w-full table-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Customer</th>
              <th className="p-3 text-left">Mobile Number</th>
              <th className="p-3 text-left">Agent</th>
              <th className="p-3 text-left">Plot Name</th>
              <th className="p-3 text-left">Location</th>
              <th className="p-3 text-left">Booking Date</th>
              <th className="p-3 text-left">Order ID</th>
            </tr>
          </thead>
          <tbody>
            {plots.map((plot) => (
              <tr key={plot._id} className="border-b border-gray-200">
                <td className="px-6 py-4 whitespace-nowrap">
                  {plot.bookedBy ? (
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={
                            plot.bookedBy.profilePicture
                              ? `${process.env.REACT_APP_API_URL}/uploads/${plot.bookedBy.profilePicture}`
                              : "https://via.placeholder.com/100"
                          }
                          alt="Customer"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {plot.bookedBy.fname} {plot.bookedBy.lname}
                        </div>
                      </div>
                    </div>
                  ) : (
                    "Not booked"
                  )}
                </td>

                <td className="p-3">{plot.bookedBy.mnumber}</td>

                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          plot.agentId?.profilePicture
                            ? `${process.env.REACT_APP_API_URL}/uploads/${plot.agentId.profilePicture}`
                            : "https://via.placeholder.com/100"
                        }
                        alt="Agent"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {plot.agentId?.fname} {plot.agentId?.lname}
                      </div>
                    </div>
                  </div>
                </td>

                <td className="p-3">{plot.name}</td>
                <td className="p-3">{plot.location}</td>
                <td className="p-3">
                  {new Date(plot.updatedAt).toLocaleDateString()}
                </td>
                <td className="p-3">{plot.bookingId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OwnerDashboard;
