import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import PlotMap from "../components/PlotMap";
import BookingModal from "../components/BookingModal";
import { getAuthToken } from "../utils/auth";

const BookingPage = () => {
  const [selectedPlots, setSelectedPlots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handlePlotsSelected = (plots) => {
    setSelectedPlots(plots);
  };

  const handleBooking = async () => {
    if (!selectedPlots.length) {
      alert("Please select plots to book");
      return;
    }

    const token = getAuthToken();
    if (!token) {
      // Redirect to login if not logged in
      navigate("/login");
      return;
    }
    // Open the modal to confirm booking
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = async (agentId) => {
    try {
      const token = getAuthToken();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/api/plots/book`, {
        plotIds: selectedPlots,
        agentReferenceId: agentId,
      });
      setIsModalOpen(false);
      // Optionally, redirect or show a success message
    } catch (error) {
      console.error("Error booking plots: ", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen animate-fade-in">
      <Navbar />
      <div className="container mx-auto my-6 px-4 md:px-8 lg:px-32 flex flex-col md:flex-row md:justify-between animate-slide-up">
        <Link to="/PlotDetailsPage" className="mb-4 md:mb-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </Link>
        <div className="flex flex-col w-full md:w-2/3 animate-zoom-in">
          <PlotMap onPlotsSelected={handlePlotsSelected} />
          <div className="flex flex-wrap mt-5 space-x-4 md:space-x-6">
            <div className="flex items-center mb-2">
              <div className="h-8 w-8 bg-yellow-500 mr-2"></div>
              <h1 className="text-sm md:text-base">Reserved Plots</h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-8 w-8 bg-gray-500 mr-2"></div>
              <h1 className="text-sm md:text-base">Available Plots</h1>
            </div>
            <div className="flex items-center mb-2">
              <div className="h-8 w-8 bg-green-700 mr-2"></div>
              <h1 className="text-sm md:text-base">Selected Plots</h1>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/4 mt-6 md:mt-0 animate-slide-in-right">
          <h1 className="text-lg md:text-xl text-black mb-4">
            RAK Groups is a residential project in Devanahalli with 17 different gardens, exclusive spaces, and easy connectivity. RAK groups represents the pinnacle of luxury living.
          </h1>
          <div className="flex space-x-2 mb-2">
            <h1 className="text-xl font-semibold text-black">APARTMENTS :</h1>
            <h1 className="text-xl font-normal text-black">3 BHK</h1>
          </div>
          <div className="flex space-x-2 mb-2">
            <h1 className="text-xl font-semibold text-black">STATUS :</h1>
            <h1 className="text-xl font-normal text-black">New Launch</h1>
          </div>
          <div className="flex space-x-2 mb-4">
            <h1 className="text-xl font-semibold text-black">PRICE :</h1>
            <h1 className="text-xl font-normal text-black">2.28 Cr Onwards*</h1>
          </div>
          <button
            onClick={handleBooking}
            className="w-full md:w-auto h-10 md:h-12 px-6 text-black rounded-full mt-4 bg-yellow-500 font-semibold"
          >
            Continue Booking
          </button>
        </div>
      </div>
      <BookingModal
        isOpen={isModalOpen}
        selectedPlots={selectedPlots}
        onClose={handleModalClose}
        onConfirm={handleModalConfirm}
      />
      <Footer />
    </div>
  );
};

export default BookingPage;
