import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAuthToken } from "../utils/auth";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const UserDashboard = () => {
  const [plots, setPlots] = useState([]);
  const { logout } = useAuth();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = getAuthToken();
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/users/profile`
        );
        setProfile(response.data);
      } catch (error) {
        console.error(
          "Error fetching user profile:",
          error.response ? error.response.data : error.message
        );
      }
    };

    const fetchPlots = async () => {
      try {
        const token = getAuthToken();
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plots/user`
        );
        setPlots(response.data);
      } catch (error) {
        console.error("Error fetching plots:", error);
      }
    };

    fetchUserProfile();
    fetchPlots();
  }, []);

  const profilePictureUrl = profile.profilePicture
    ? `${process.env.REACT_APP_API_URL}/uploads/${profile.profilePicture}`
    : "https://via.placeholder.com/100";

  return (
    <div className="flex min-h-screen bg-gray-100">
  {/* Sidebar */}
  <div className="w-64 fixed top-0 left-0 h-full bg-white shadow-lg flex flex-col justify-between">
    <div>
      <div className="p-4 border-b w-full md:w-48 mx-auto">
        <Link to="/home" className="block w-fit">
          <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
            <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" />
          </svg>
        </Link>
        <div className="w-full h-40 rounded-full overflow-hidden">
          <img
            src={profilePictureUrl}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
        <h2 className="mt-2 text-center text-lg font-semibold">
          {profile.fname} {profile.lname}
        </h2>
      </div>

      <div className="p-4">
        <nav className="space-y-2">
        <Link
            to="/user-dashboard"
            className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13 21V11h8v10h-8zM3 13V3h8v10H3zm6-2V5H5v6h4zM3 21v-6h8v6H3zm2-2h4v-2H5v2zm10 0h4v-6h-4v6zM13 3h8v6h-8V3zm2 2v2h4V5h-4z" />
            </svg>
            Dashboard
          </Link>
          <Link
            to="/user-settings"
            className="flex p-2 items-center gap-4 rounded hover:bg-gray-200"
          >
            <svg viewBox="0 0 512 512" fill="currentColor" className="size-6">
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M262.29 192.31a64 64 0 1057.4 57.4 64.13 64.13 0 00-57.4-57.4zM416.39 256a154.34 154.34 0 01-1.53 20.79l45.21 35.46a10.81 10.81 0 012.45 13.75l-42.77 74a10.81 10.81 0 01-13.14 4.59l-44.9-18.08a16.11 16.11 0 00-15.17 1.75A164.48 164.48 0 01325 400.8a15.94 15.94 0 00-8.82 12.14l-6.73 47.89a11.08 11.08 0 01-10.68 9.17h-85.54a11.11 11.11 0 01-10.69-8.87l-6.72-47.82a16.07 16.07 0 00-9-12.22 155.3 155.3 0 01-21.46-12.57 16 16 0 00-15.11-1.71l-44.89 18.07a10.81 10.81 0 01-13.14-4.58l-42.77-74a10.8 10.8 0 012.45-13.75l38.21-30a16.05 16.05 0 006-14.08c-.36-4.17-.58-8.33-.58-12.5s.21-8.27.58-12.35a16 16 0 00-6.07-13.94l-38.19-30A10.81 10.81 0 0149.48 186l42.77-74a10.81 10.81 0 0113.14-4.59l44.9 18.08a16.11 16.11 0 0015.17-1.75A164.48 164.48 0 01187 111.2a15.94 15.94 0 008.82-12.14l6.73-47.89A11.08 11.08 0 01213.23 42h85.54a11.11 11.11 0 0110.69 8.87l6.72 47.82a16.07 16.07 0 009 12.22 155.3 155.3 0 0121.46 12.57 16 16 0 0015.11 1.71l44.89-18.07a10.81 10.81 0 0113.14 4.58l42.77 74a10.8 10.8 0 01-2.45 13.75l-38.21 30a16.05 16.05 0 00-6.05 14.08c.33 4.14.55 8.3.55 12.47z"
              />
            </svg>
            Settings
          </Link>
        </nav>
      </div>
    </div>

    <button
      onClick={logout}
      className="flex p-2 items-center gap-4 rounded hover:bg-gray-200 mb-4 mx-4"
    >
      <svg viewBox="0 0 24 24" fill="currentColor" className="size-6">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 011-1h14a1 1 0 011 1v18a1 1 0 01-1 1H5a1 1 0 01-1-1v-3zm2-7h7v2H6v3l-5-4 5-4v3z" />
      </svg>
      Logout
    </button>
  </div>

  {/* Main content */}
  <div className="flex-1 p-8 ml-64 overflow-y-auto">
  <h1 className="text-2xl font-semibold mb-4">Booked Plots</h1>
  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Plot Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Agent
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            CP Mobile Number
          </th>

          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Location
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Booking ID
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Booking Date
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {plots.map((plot) => (
          <tr key={plot._id}>
            <td className="px-6 py-4 whitespace-nowrap">{plot.name}</td>
            <td className="px-6 py-4 whitespace-nowrap">{plot.status}</td>

            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex items-center">
                <div className="flex-shrink-0 h-10 w-10">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={
                      plot.agentId?.profilePicture
                        ? `${process.env.REACT_APP_API_URL}/uploads/${plot.agentId.profilePicture}`
                        : "https://via.placeholder.com/100"
                    }
                    alt="Agent"
                  />
                </div>
                <div className="ml-4">
                  <div className="text-sm font-medium text-gray-900">
                    {plot.agentId?.fname} {plot.agentId?.lname}
                  </div>
                </div>
              </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">{plot.agentId.mnumber}</td>
            <td className="px-6 py-4 whitespace-nowrap">{plot.location}</td>
            <td className="px-6 py-4 whitespace-nowrap">{plot.bookingId}</td>

            {/* Booking Date */}
            <td className="px-6 py-4 whitespace-nowrap">
              {new Date(plot.updatedAt).toLocaleDateString()}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>


</div>

  );
};

export default UserDashboard;
